import React from "react";
import Layout from "../components/layout";
import Title from "../components/title";
import SEO from "../components/seo";

import { graphql, useStaticQuery, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Styles
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import * as styles from "../styles/pages/experiences.module.scss";

const Locations = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulLocations {
          edges {
            node {
              description {
                raw
              }
              images {
                gatsbyImageData(
                  quality: 100
                  height: 300
                  width: 300
                  layout: FULL_WIDTH
                )
              }
              slug
              node_locale
              title
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Title picture title="Locations" />
      <SEO title="Locations" />
      <Container className="mt-5 pt-5">
        <Row className="g-5">
          {data.allContentfulLocations.edges.map(({ node }, idx) => {
            return (
              <Col md={4}>
                <Card
                  className={`${styles.card} p-0`}
                  onClick={() => navigate(`/locations/${node.slug}`)}
                >
                  <GatsbyImage
                    image={node.images[0].gatsbyImageData}
                    alt={node.title}
                  />

                  <Card.Body className="p-4">
                    <Card.Title>{node.title}</Card.Title>
                    <hr />
                    <Card.Text>
                      {node.description.raw.slice(75, 300)}...
                    </Card.Text>
                    <Button variant="secondary-rounded">Read More</Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Layout>
  );
};

export default Locations;
